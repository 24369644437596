import React, { useState, useEffect } from 'react';
import { useToast } from "@chakra-ui/react"; // Import the useToast hook
import { useAuth } from '../../context/AuthContext'
import { Box, Button, Checkbox, FormControl, FormLabel, Input, Select } from "@chakra-ui/react";
import Instance from '../../utils/instanceAuthentication';

const MultipleImageUpload = () => {
  const { accessToken } = useAuth();
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [productForm, setProductForm] = useState({
    name: '',
    description: '',
    price: '',
    stock: '',
    category: '',
    availability: false,
    uploaded_images: [],
  });

  const toast = useToast(); // Initialize the useToast hook

  useEffect(() => {
    const fetchData = async () => {
      try {
        const categoryData = await Instance('create/category/').then(response => response.data);
        setCategoryOptions(categoryData);
      } catch (error) {
        console.log(`error while fetching product ${error}`);
      }
    };
    fetchData();
  }, [accessToken]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setProductForm({
      ...productForm,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files);
    setProductForm({
      ...productForm,
      uploaded_images: files,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = new FormData();
    Object.keys(productForm).forEach((key) => {
      if (key === 'uploaded_images') {
        productForm[key].forEach((file) => {
          form.append('uploaded_images', file);
        });
      } else {
        form.append(key, productForm[key]);
      }
    });

    try {
      const response = await Instance.post('create/product/', form, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      console.log(response.data);

      toast({
        title: "Product Created",
        status: "success",
        position:'top',
        duration: 3000,
        isClosable: true,
      });

      setProductForm({
        name: '',
        description: '',
        price: '',
        stock: '',
        category: '',
        availability: false,
        uploaded_images: [],
      });
    } catch (error) {
      console.log(`error while create product ${error}`);
      toast({
        title: "Error while creating check all field are in correct format",
        status: "error",
        position:'top',
        duration: 3000,
        isClosable: true,
      });

    }
  };

  return (
    <div>
      <Box maxW="sm" mt={'5%'} mx="auto" boxShadow="base" p="6">
        <form encType="multipart/form-data">
        <FormControl mb="4">
      <FormLabel htmlFor="name">Name:</FormLabel>
      <Input
        type="text"
        name="name"
        value={productForm.name}
        onChange={handleChange}
        required
      />
    </FormControl>

    <FormControl mb="4">
      <FormLabel htmlFor="category">Category:</FormLabel>
      <Select
        name="category"
        value={productForm.category}
        onChange={handleChange}
        required
      >
        <option value="" disabled>Select a Category</option>
        {categoryOptions.map(category => (
          <option key={category.id} value={category.id}>
            {category.name}
          </option>
        ))}
      </Select>
    </FormControl>

    <FormControl mb="4">
      <FormLabel htmlFor="description">Description:</FormLabel>
      <Input
        type="text"
        name="description"
        value={productForm.description}
        onChange={handleChange}
        required
      />
    </FormControl>

    <FormControl mb="4">
      <FormLabel htmlFor="image">Image:</FormLabel>
      <Input type="file" name="uploaded_images" accept="image/*" multiple onChange={handleImageUpload} />
    </FormControl>

    <FormControl mb="4">
      <FormLabel htmlFor="price">Price:</FormLabel>
      <Input
        type="number"
        name="price"
        value={productForm.price}
        onChange={handleChange}
        required
      />
    </FormControl>

    <FormControl mb="4">
      <FormLabel htmlFor="stock">Stock:</FormLabel>
      <Input
        type="number"
        name="stock"
        value={productForm.stock}
        onChange={handleChange}
        required
      />
    </FormControl>

    <FormControl mb="4">
      <Checkbox
        name="availability"
        isChecked={productForm.availability}
        onChange={handleChange}
      >
        Availability
      </Checkbox>
    </FormControl>

          <Button colorScheme="teal" type="button" onClick={handleSubmit}>
            Create Product
          </Button>
        </form>
      </Box>
    </div>
  );
};

export default MultipleImageUpload;
