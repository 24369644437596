import React, { useState, useEffect } from 'react';
import { useToast } from "@chakra-ui/react";
// import { useAuth } from '../context/AuthContext';
import { Box, Button, Checkbox, FormControl, FormLabel, Input, Select } from "@chakra-ui/react";
import Instance from '../utils/instanceAuthentication';
import { useNavigate, useParams } from 'react-router-dom';

const UpdateProductAdmin = () => {
//   const { accessToken } = useAuth();
  const [categoryOptions, setCategoryOptions] = useState([])
  const [productForm, setProductForm] = useState({
    name: '',
    description: '',
    price: '',
    stock: '',
    category: '',
    availability: false,
    
  });
  const { slug } = useParams();
  const toast = useToast();
  const navigate = useNavigate()

  useEffect(() => {
    const fetchData = async () => {
      try {
        const productData = await Instance.get(`update/product/${slug}/`).then(response => response.data);
        const categoryData = await Instance.get('list/category/').then(response => response.data)
        setProductForm(productData);
        setCategoryOptions(categoryData)
      } catch (error) {
        console.log(`error while fetching product ${error}`);
      }
    };
    fetchData();
  }, [slug]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setProductForm({
      ...productForm,
      [name]: type === 'checkbox' ? checked : value,
    });
  };



  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = new FormData();
     form.append('name',  productForm.name)
     form.append('price',  productForm.price)
     form.append('description', productForm.description)
     form.append('stock', productForm.stock)
     form.append('category', productForm.category)
     form.append('availability', productForm.availability)

    try {
      const response = await Instance.put(`update/product/${slug}/`, form, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      console.log(response.data);

      toast({
        title: "Product Updated Successfully",
        status: "success",
        position: 'top',
        duration: 3000,
        isClosable: true,
      });
      navigate('/')

    } catch (error) {
      console.log(`error while create product ${error}`);
      toast({
        title: "Error while creating. Check all fields are in correct format",
        status: "error",
        position: 'top',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <div>
      <Box maxW="sm" mt={'5%'} mx="auto" boxShadow="base" p="6">
        <form encType="multipart/form-data">
          <FormControl mb="4">
            <FormLabel htmlFor="name">Name:</FormLabel>
            <Input
              type="text"
              name="name"
              value={productForm.name}
              onChange={handleChange}
              required
            />
          </FormControl>

          <FormControl mb="4">
            <FormLabel htmlFor="category">Category:</FormLabel>
            <Select
              name="category"
              value={productForm.category}
              onChange={handleChange}
              required
            >
              <option value="" disabled>Select a Category</option>
              {categoryOptions.map(category=>(
                <option key={category.id} value={category.id}>{category.name}</option>
              ))}
              
            
            </Select>
          </FormControl>

          <FormControl mb="4">
            <FormLabel htmlFor="description">Description:</FormLabel>
            <Input
              type="text"
              name="description"
              value={productForm.description}
              onChange={handleChange}
              required
            />
          </FormControl>


          <FormControl mb="4">
            <FormLabel htmlFor="price">Price:</FormLabel>
            <Input
              type="number"
              name="price"
              value={productForm.price}
              onChange={handleChange}
              required
            />
          </FormControl>

          <FormControl mb="4">
            <FormLabel htmlFor="stock">Stock:</FormLabel>
            <Input
              type="number"
              name="stock"
              value={productForm.stock}
              onChange={handleChange}
              required
            />
          </FormControl>

          <FormControl mb="4">
            <Checkbox
              name="availability"
              isChecked={productForm.availability}
              onChange={handleChange}
            >
              Availability
            </Checkbox>
          </FormControl>

          <Button colorScheme="teal" type="button" onClick={handleSubmit}>
            Update Product
          </Button>
        </form>
      </Box>
    </div>
  );
};

export default UpdateProductAdmin;
