import React, { useEffect, useState } from 'react';

import Instance from '../utils/instanceAuthentication';
import { useNavigate } from 'react-router-dom';
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
 
  Stack,
  Button,
  Heading,

  useColorModeValue,
  useToast,
} from '@chakra-ui/react'
import { useAuth } from '../context/AuthContext';

function SignInComponent() {
    const Navigate = useNavigate()
    const toast = useToast()
    const {accessToken} = useAuth()

    const [userLoginData, setUserLoginData] = useState({
        username: '',
        password: '',
      });
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setUserLoginData({
          ...userLoginData,
          [name]: value,
        });
      };
      
  useEffect(()=>{
   try {
    if(accessToken){
      Navigate('/')
    }
   } catch (error) {
    console.log(error)
   }

  },[accessToken, Navigate])
      
    
      const signInForm = async (e) => {
        e.preventDefault();
        try {
          const response = await Instance.post('token/', userLoginData);
      
          if (response.status === 200) {
            localStorage.setItem('access_token', response.data.access);
            localStorage.setItem('refresh_token', response.data.refresh);
            Navigate('/');
            window.location.reload();
          } else {
            // Handle other status codes
            console.error('Unexpected response status:', response.status);
          }
        } catch (error) {
          if (error.response && error.response.status === 401) {
            const responseData = error.response.data;
            if (responseData && responseData.code === 'token_not_valid') {
              console.error('Token is invalid or expired.');
              // Handle the token expiration, e.g., redirect to login page or show an error message
            } else {
              // console.error('Unauthorized access. Invalid login credentials.');
              toast({
            title: "Unauthorized access. Invalid login credentials.",
            status: 'error',
            position:'top',
            duration: 3000,
            isClosable: true,
          });
              // Handle unauthorized access, e.g., redirect to login page or show an error message
            }
          } else {
            console.error('An error occurred:', error.message);
          }
          
        }
      };
     
    
      return (
      
           <Flex
           m={'0 auto'}
     
      align={'center'}
      justify={'center'}
      >
      <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
        <Stack align={'center'}>
          <Heading fontSize={'4xl'}>Sign in to your account</Heading>
        </Stack>
        <Box
          rounded={'lg'}
          bg={useColorModeValue('white', 'gray.700')}
          boxShadow={'lg'}
          p={8}>
          <Stack spacing={4}>
            <FormControl id="email">
              <FormLabel>Email address</FormLabel>
              <Input type="email" 
              value={userLoginData.username}
              name='username'
              onChange={handleChange}
              />
            </FormControl>
            <FormControl id="password">
              <FormLabel>Password</FormLabel>
              <Input
               type="password"
              name='password'
              value={userLoginData.password}
              onChange={handleChange}
               />
            </FormControl>
            <Stack spacing={10}>
              <Stack
                direction={{ base: 'column', sm: 'row' }}
                align={'start'}
                justify={'space-between'}>
              </Stack>
              <Button
              onClick={signInForm}
                bg={'blue.400'}
                color={'white'}
                _hover={{
                  bg: 'blue.500',
                }}>
                Sign in
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Flex>

  
      );
    };
export default SignInComponent;