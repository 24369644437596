import { BrowserRouter as Router, Route, Routes  } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import './App.css'

import { Box } from '@chakra-ui/react';



import Dashboard from './components/AdminPanel/adminDashBoard';
import SignInComponent from './components/Auth/signIn';
import SignOutComponent from './components/Auth/signOut';
import UpdateProductAdmin from './components/AdminPanel/updateProductAdmin';

function App() {
  return (
    <> 
   
      <Router>
         <Header/>
         <Box minH={'100vh'}>
          <Routes>
          <Route path="/signin/" element={<SignInComponent/>} />
          <Route path="/signout/" element={<SignOutComponent/>} />
          <Route path="/" element={<Dashboard/>} />
          <Route path="/update/product/:slug/" element={<UpdateProductAdmin/>} />
          
           
        </Routes>
      </Box>
       <Footer/>
      </Router> 
   
     
    </>
  );
}

export default App;