import React, { useEffect, useState } from 'react'
import { Box, Button,  FormControl, FormLabel, Input, Select } from "@chakra-ui/react";
import Instance from '../../utils/instanceAuthentication';
import { useAuth } from '../../context/AuthContext';

const AboutUsImagesForm = () => {
  const {accessToken} = useAuth()
    const [authorDetails, setAuthorDetails] = useState([])
    const [aboutUsImage, setAboutUsImage] = useState({
        author:'',
        images:null,
    })

    
    const fetchAuthorData = async ()=>{
        try {
            const author = await Instance.get('create/images/about-us/').then(response=> response.data);
            setAuthorDetails(author)
        } catch (error) {
            console.log(`error while fetching author ${error.message}`)            
        }
       
    }
    useEffect(()=>{
        fetchAuthorData()
    },[])

    const handleChange = (e) => {
        const { name, value} = e.target;
        setAboutUsImage({
          ...aboutUsImage,
          [name]: value,
        });
      };
  
     
    
    

      const handleImageUpload = (e) => {
        const files = Array.from(e.target.files);
        setAboutUsImage({
          ...aboutUsImage,
          images: files,
        });
      };




      const handleSubmit = async (e) => {
        e.preventDefault();
        
        const form = new FormData();
        Object.keys(aboutUsImage).forEach((key) => {
          if (key === 'images') {
            aboutUsImage[key].forEach((file) => {
              form.append('images', file);
            });
          } else {
            form.append(key, aboutUsImage[key]);
          }
        });
     
        try {
          await Instance.post('create/images/about-us/', form, {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${accessToken}`,
            }
          }).then(response => 
            console.log(response.data));
         
        } catch (error) {
          console.error('Error creating about images:', error);
        }
      };
      
  
  return (
    <>
    <Box maxW="sm" mt={'5%'} mx="auto" boxShadow="base"  p="6">
  <form encType="multipart/form-data">
  

  <FormControl mb="4">
  <FormLabel htmlFor="author">Author:</FormLabel>
  <Select
    name="author"
    value={aboutUsImage.author}
    onChange={handleChange}
    required
  >
    <option value="" disabled> Select Author </option>
    {authorDetails
      .filter((author, index, self) => 
        index === self.findIndex(a => a.author.id === author.author.id)
      )
      .map(author => (
        <option key={author.author.id} value={author.author.id}>
          {author.author.name}
        </option>
      ))}
  </Select>
</FormControl>

    

    <FormControl mb="4">
      <FormLabel htmlFor="image">Image:</FormLabel>
      <Input type="file" name="images" accept="image/*" multiple onChange={handleImageUpload} />
    </FormControl>

    
    

    <Button onClick={handleSubmit} colorScheme="teal" type="button" >
      Save About-Us Images
    </Button>
  </form>
</Box>
    </>
  )
}

export default AboutUsImagesForm