import React, {  useEffect, useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  VStack,
  HStack,
  
  useDisclosure,
} from '@chakra-ui/react';
import { BiSidebar } from 'react-icons/bi';

import MultipleImageUpload from './forms/productImageForm';
import AboutUsImagesForm from './forms/aboutUsImagesForm';

import { CloseIcon } from '@chakra-ui/icons';
import ListProductAdminPanel from './ProductsAdminPanel';
import { useAuth } from '../context/AuthContext';
import { Link, useNavigate } from 'react-router-dom';


const AdminDashboard = () => {
  const { isOpen, onToggle } = useDisclosure();
  const [activeComponent, setActiveComponent] = useState('TableDisplay');
  const {accessToken} = useAuth()
  const [authenticated, setAuthenticated] = useState(false)
  const navigate = useNavigate()


  const handleClick = (componentName) => {
    setActiveComponent(componentName);
    onToggle();
  };



  useEffect(()=>{
     const checkCredential = ()=>{
     try {
      if(accessToken){
        setAuthenticated(true)
      }else{
        setAuthenticated(false)
        navigate('/signin/')
      }
    } catch (error) {
      console.log(error)
    }
  }
  checkCredential() 
 
  },[navigate, accessToken])

 

  const renderComponent = () => {
    switch (activeComponent) {
      case 'ProductForm':
        return <MultipleImageUpload />;
      case 'AboutImage':
        return <AboutUsImagesForm />;
      case 'ListProduct':
        return <ListProductAdminPanel />;
      default:
        return <ListProductAdminPanel />;
    }    
  };



  return (
    <>
 {authenticated && 

  <Box
        display="flex"
        flexDirection={{ base: 'column', md: 'row' }}
        minH="100vh"
      >

        <HStack  p="4" bg="gray.700" color="white" w={{ base: '100%', md: '7.5%', lg:'5%' }}>
          <IconButton
          icon={isOpen ? <CloseIcon />: <BiSidebar />}
            onClick={onToggle}
            aria-label="Toggle Navigation Bar"
          />
        </HStack>

        <VStack
          spacing={4}
          align="start"
          bg="gray.700"
          color="white"
          width={isOpen ? { base: '100%', md: '20%' } : '0'}
          p="4"
          transition="width 0.3s ease"
          overflow="hidden"
          visibility={isOpen ? 'visible' : 'hidden'}
          
        >
          {isOpen && (
            <>
              <Button onClick={() => handleClick('ProductForm')}>Create Product</Button>
              <Button onClick={() => handleClick('AboutImage')}>About-Us Images</Button>
              <Button onClick={() => handleClick('ListProduct')}>Products</Button>
                  
                  <Button colorScheme="red" as={Link} to={'/signout/'}>SignOut</Button>
            </>
          )}
        </VStack>

        {/* Main Content */}
        <Box flex="1" p={{ base: '4', md: '4' }}>
          {renderComponent()}
        </Box>
      </Box>
 }
      
    </>
  );
};

export default AdminDashboard;
