import React, { useEffect, useState } from 'react';
import axiosInstance from '../utils/axiosInstance';
import {
  Box,
  Flex,
  Select,
  Image,
  Button,
} from "@chakra-ui/react";
import { Link, useNavigate, useParams } from "react-router-dom";
import LoadingComponent from '../utils/LoadingAnimation';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from '@chakra-ui/react'


const ListProductAdminPanel = () => {
  const [listProductDetails, setListProductDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [nextPage, setNextPage] = useState(true);
  const [previousPage, setPreviousPage] = useState(false);
  const { ordering_param } = useParams();
  const [sortingOption, setSortingOption] = useState(ordering_param || '');
  const router = useNavigate();



  const fetchData = async (page, ordering) => {
    try {
      const productData = await axiosInstance.get(`admin/products/?page=${page}&ordering=${ordering}`);
      setListProductDetails(productData.data.results || []); 
      setNextPage(!!productData.data.next);
      setPreviousPage(!!productData.data.previous);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchData(currentPage, sortingOption);
  }, [currentPage, sortingOption]);

  const nextPageButton = async () => {
    if (nextPage) {
      setIsLoading(true)
      setCurrentPage(currentPage + 1);

    }
  }

  const PreviousPageButton = async () => {
    if (previousPage) {
      setIsLoading(true)
      setCurrentPage(currentPage - 1);
    }
  }

  const handleSortingChange = (e) => {
    const selectedOption = e.target.value;

    router(`?ordering=${selectedOption}`);
    setSortingOption(selectedOption);
    setIsLoading(true);
  };
  return (
    isLoading ? <LoadingComponent /> : (
      <Flex  direction="column" align="flex-start" maxW="100%">
        <Box mx={2.5} my={2.5} w="100%">
          <Select value={sortingOption} onChange={handleSortingChange} maxW="10%">
            <option value="" disabled>Sort</option>
            <option value="price">Price - Low to High</option>
            <option value="-price">Price - High to Low</option>
            <option value="created_at">Existing</option>
            <option value="-created_at">Latest</option>
          </Select>
        </Box>
        <Flex
             flexWrap="wrap"
             justifyContent="center"
             maxW={['100%', '100%', 'lg']} ml={['0%', '0%', '5%']}
             minH={['sm', 'md', 'md']}
        >
       <Table variant={'simple'} size={['xs', 'sm', 'md']} width={['100%', '100%', 'auto']} >
    <Thead>
      <Tr>
        <Th>Index No</Th>
        <Th>Product Name</Th>
        <Th>Price</Th>
        <Th >Stock</Th>
      </Tr>
    </Thead>    
    <Tbody >
     {listProductDetails.map((product, index) => ( 
       <Tr key={product.id}>
        <Td >{index+1}</Td>
        <Td >{product.name}<><Image m={'10px auto'} objectFit={'contain'} w={'50px'} h={'50px'} src={`https://api.rajengg.co.in${product.first_image}/`} alt="Card Image" /></></Td> 
        <Td > {product.price}</Td>
        <Td > {product.stock}</Td>
        
        <Td> <Button as={Link} to={`/update/product/${product.slug}/`}>Update</Button> </Td>
      </Tr> ))} 
    </Tbody>
          </Table>
        </Flex>
        <Flex align="center" justify="center" mt={4} w="100%">
          {previousPage && (
            <Button onClick={PreviousPageButton} mr={2}>
              Previous
            </Button>
          )}
          <Box className="page-number" mx={2}>
            Page&nbsp;{currentPage}
          </Box>
          {nextPage && (
            <Button onClick={nextPageButton} ml={2}>
              Next
            </Button>
          )}
        </Flex>
      </Flex>
    )
  )
}

export default ListProductAdminPanel;


