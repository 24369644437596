import { useEffect } from "react";
import Instance from "../utils/instanceAuthentication";
import { useNavigate } from "react-router-dom";


// SignOutComponent.js

function SignOutComponent() {
    const navigate = useNavigate()
    
    useEffect(() => {
        
        const handleSignOut = async () => {
            const refresh_token = localStorage.getItem('refresh_token');
            try {
                const response = await Instance.post('token/blacklist/', { refresh_token: refresh_token });

                if (response.status === 200) {
                    localStorage.removeItem('access_token');
                    localStorage.removeItem('refresh_token');
                    navigate('/signin/')
                    window.location.reload()
                   
                } else {
                    console.log('something went wrong');
                }
            } catch (error) {
                console.error(error);
            }
            
        };

        handleSignOut();
       
    }, [navigate]);

    return null;
}

export default SignOutComponent;