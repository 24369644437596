import React from 'react';
import {
  Box,
  Flex,
  Image,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';




const Header = () => {

  return (
    <Box as="header" p="4" bgColor="blue.300" color="white">
      <Flex alignItems="center">
        <Box mx={0}>
          <Link to={'/'}>
           <Image
                src='/companylogo.png'
                alt='Company Logo'
                style={{ maxWidth: '100%', height: 'auto', maxHeight: '50px' }}
              />

          </Link>
             
        
        </Box>


      </Flex>
     
        </Box>
     
   
  );
};

export default Header;
