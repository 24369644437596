import React from 'react';
import {
  Box,
  chakra,
  Container,
  SimpleGrid,
  Stack,
  Text,
  VisuallyHidden,
  useColorModeValue,
} from '@chakra-ui/react';
import { FaFacebook,  FaInstagram,  FaWhatsapp,  } from 'react-icons/fa';





const SocialButton = ({ children, label, href }) => {
  return (
    <chakra.button
    
      bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
      rounded={'full'}
      target='_blank'
      w={8}
      h={8}
      cursor={'pointer'}
      as={'a'}
      href={href}
      display={'inline-flex'}
      alignItems={'center'}
      justifyContent={'center'}
      transition={'background 0.3s ease'}
      _hover={{
        bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
      }}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
}



function Footer() {
  return (
    <Box
      bg={useColorModeValue('gray.50', 'gray.900')}
      color={useColorModeValue('gray.700', 'gray.200')}
    >
      <Container as={Stack} maxW={'6xl'} py={10}>
        <SimpleGrid
          templateColumns={{ sm: '1fr 1fr', md: '2fr 1fr 1fr 2fr' }}
          spacing={8}
          ml={'25%'}
        >
          <Stack spacing={6}>
      
            <Text fontSize={'sm'} whiteSpace={'nowrap'}>© 2023 Raj Engineering.</Text>
            <Stack direction={'row'}  spacing={6}>
              
              <SocialButton label={'Whatsapp'} href={'https://whatsapp.com/channel/0029Va5Dzkw9sBIHtVNaHY44'}>
                <FaWhatsapp />
              </SocialButton>
              
              <SocialButton label={'Facebook'}  href={'https://www.facebook.com/rajengineering72'}>
                <FaFacebook />
              </SocialButton>
              <SocialButton label={'Instagram'}  href={'https://www.instagram.com/rajengineering__/'}>
                <FaInstagram />
              </SocialButton>
            </Stack>
          </Stack>

        </SimpleGrid>
      </Container>
    </Box>
  );
}

export default Footer;
